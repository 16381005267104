import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import SqlActions from '../redux/SqlQueries';
import Header from './Header';
import Button from '@material-ui/core/Button';
import AdsComponent from './AdsComponent';
import Info from '@material-ui/icons/Info';
import SchemaPreviewDialog from './SchemaPreviewDialog';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const styles = (theme) => ({
  page: {
    height: '100%',
  },
  content: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    padding: '0px 16px',
    gap: '12px',
  },
  textContainer: {
    width: '100%',
  },
  resultTable: {
    width: '100%',
    maxHeight: '400px',
  },
  headerAdd: {},
  spacedApartLine: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '12px',
    alignItems: 'end',
    marginTop: '-10px',
  },
  schemaSelect: {
    width: '200px',
  },
  schemaAndExecute: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
  },
  executeButton: {
    marginTop: '10px',
  },
  schemaInfoButton: {
    marginTop: '10px',
    marginRight: '10px',
  },
  description: {
    fontSize: '12px',
  },
  infoContainer: {
    height: '15px',
    width: '15px',
    marginTop: '-4px',
  },
  infoButton: {
    height: '15px',
    width: '15px',
    marginRight: '5px',
  },
  infoAndSelect: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
  },
});

function renderTableHeaders(columns) {
  return (
    <TableHead>
      <TableRow>
        {columns.map((column, columnIndex) => (
          <TableCell key={columnIndex}>{column}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function renderTableRows(rows) {
  return (
    <TableBody>
      {rows.map((row, rowIndex) => (
        <TableRow
          key={rowIndex}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          {row.map((cell, cellIndex) => (
            <TableCell key={`${rowIndex}-${cellIndex}`}>{cell}</TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}

class SqlQueriesPage extends Component {
  componentDidMount() {
    window.gtag('event', 'page_view', {
      page_path: '/sql-queries',
    });
  }

  handlePostSqlClick = () => {
    this.props.setDisableButton(true);
    const payload = {
      schema: this.props.schema,
      sql: this.props.sql,
    };
    setTimeout(() => {
      this.props.setDisableButton(false);
    }, 2000);

    this.props.executeSql(payload);
  };

  handleSqlTextChanged = (event) => {
    this.props.setSqlText(event.target.value);
  };

  handleChemaSelectChange = (event) => {
    this.props.setSchema(event.target.value);
  };

  render() {
    const { classes, sql, schema, executeSqlData, buttonDisabled } = this.props;
    const response = executeSqlData;
    let rows = null;
    let columns = null;

    if (
      response?.columns?.length > 0 &&
      response?.data?.length > 0 &&
      !response.error
    ) {
      columns = response.columns;
      rows = response.data;
    }
    return (
      <section className={classes.page}>
        <Header />
        <SchemaPreviewDialog schema={schema} />
        <div className={classes.content}>
          <AdsComponent className={classes.headerAdd} dataAdSlot="3011416617" />
          <div className={classes.spacedApartLine}>
            <div>
              <Typography variant="h6">Run your SQL queries</Typography>
              <Typography className={classes.description}>
                Here, you can run queries on datasets from "Database Systems:
                Introduction to Databases and Data Warehouses, 2nd Edition"
              </Typography>
            </div>
            <div className={classes.schemaAndExecute}>
              <div className={classes.infoAndSelect}>
                {schema && (
                  <Tooltip title="Click to see schema preview" placement="top">
                    <Button
                      variant="contained"
                      color="default"
                      onClick={() => this.props.schemaPreviewShow()}
                      className={classes.schemaInfoButton}
                      disabled={!schema}
                    >
                      <Info className={classes.infoButton} />
                      <>Schema info</>
                    </Button>
                  </Tooltip>
                )}
                <FormControl>
                  <InputLabel id="demo-select-small-label">
                    Choose schema
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Schema"
                    className={classes.schemaSelect}
                    value={schema}
                    onChange={this.handleChemaSelectChange}
                  >
                    <MenuItem value={'zagi'}>
                      {'ZAGI (second edition)'}
                    </MenuItem>
                    <MenuItem value={'hafh'}>
                      {'HAFH (second edition)'}
                    </MenuItem>
                    <MenuItem value={'extructaym'}>{'ExtructaYM'}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handlePostSqlClick}
                className={classes.executeButton}
                disabled={!sql || !schema || buttonDisabled}
              >
                Execute SQL Query
              </Button>
            </div>
          </div>

          <TextField
            className={classes.textContainer}
            multiline
            rows={6}
            label="SQL Query"
            variant="outlined"
            value={sql}
            onChange={this.handleSqlTextChanged}
            InputLabelProps={{ shrink: true }}
            placeholder="e.g. 'SELECT * FROM table_name'"
          />
          {rows && columns && (
            <TableContainer component={Paper} className={classes.resultTable}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                {renderTableHeaders(columns)}
                {renderTableRows(rows)}
              </Table>
            </TableContainer>
          )}
          {response?.data?.length === 0 && (
            <Typography variant="h6">
              No data returned from the query
            </Typography>
          )}
          {response?.error && (
            <Typography variant="h6" color="error">
              {response?.error}
            </Typography>
          )}
          <AdsComponent dataAdSlot="5467756232" />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sql: state.sqlQueries.sql,
    schema: state.sqlQueries.schema,
    executeSqlData: state.sqlQueries.executeSqlData,
    buttonDisabled: state.sqlQueries.disableButton,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    executeSql: (sql) => dispatch(SqlActions.executeSql(sql)),
    setSqlText: (sql) => dispatch(SqlActions.setSqlText(sql)),
    setSchema: (schema) => dispatch(SqlActions.setSchema(schema)),
    setDisableButton: (disable) =>
      dispatch(SqlActions.setDisableButton(disable)),
    schemaPreviewShow: () => dispatch(SqlActions.schemaPreviewShow()),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SqlQueriesPage)
);
